import { createSlice } from '@reduxjs/toolkit';
import { getStore } from './thunks';
import { Store } from '../../types';
import ReactGA from 'react-ga4';

interface InitialState {
  loading: boolean;
  error: any;
  data: null | Store;
}

const initialState: InitialState = {
  loading: false,
  error: null,
  data: null,
};

const slice = createSlice({
  name: 'store',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStore.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getStore.fulfilled, (state, action) => {
      const { employees, ...store } = action.payload;

      ReactGA.gtag(
        'config',
        process.env.REACT_APP_GA_MEASUREMENT_ID,
        {
          enterpriseId: store.enterpriseId,
          externalId: store.externalId,
          storeId: store.id,
        },
      );

      state.loading = false;
      state.error = null;
      state.data = {
        ...store,
        appSettings: {
          slotsLeadingDays: Math.max(store.settings.appointmentLeadingDays, store.settings.appointmentEmployeeLeadingDays),
        },
      };
    });
    builder.addCase(getStore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error; // todo check it
      state.data = null;
    });
  },
});

export default slice.reducer;
