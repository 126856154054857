import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Employee, Shift } from '../../types';
import { fetchEmployees, getEmployeesByIds, getStoreEmployees } from './thunks';
import { TimeHelper } from '../../utils';

interface InitialState {
  storeEmployees: {
    loading: boolean;
    error: any;
    data: {
      employees: Employee[];
      shifts: Record<string, Shift[]>;
    },
  };
}

const initialState: InitialState = {
  storeEmployees: {
    loading: false,
    error: null,
    data: {
      employees: [],
      shifts: {},
    },
  },
};

const slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    updateShifts: (
      state,
      action: PayloadAction<{
        shifts: { create: Shift[], update: Shift[], delete: Shift[] },
        date: string,
      }>,
    ) => {
      const { shifts: payloadShifts, date } = action.payload;

      const existingShifts = state.storeEmployees.data.shifts[date] || [];

      const shiftsByEmployeeId = Object.entries(payloadShifts).reduce<Record<string, Record<string, Shift>>>((acc, [operation, items]) => {
        items.forEach((shift) => {
          acc[operation][shift.employeeId] = shift;
        });

        return acc;
      }, { create: {}, update: {}, delete: {} });

      const updatedShifts: Shift[] = [];
      existingShifts.forEach((item) => {
        if (shiftsByEmployeeId.update[item.employeeId]) {
          updatedShifts.push({ ...item, ...shiftsByEmployeeId.update[item.employeeId] });
          return;
        }
        if (shiftsByEmployeeId.delete[item.employeeId]) {
          return;
        }
        updatedShifts.push(item);
      });

      state.storeEmployees.data.shifts[date] = updatedShifts.concat(payloadShifts.create);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmployees.pending, (state) => {
      state.storeEmployees.loading = true;
      state.storeEmployees.error = null;
    });
    builder.addCase(fetchEmployees.fulfilled, (state, action) => {
      state.storeEmployees.loading = false;
      state.storeEmployees.error = null;

      const employeesByIdMap = (state.storeEmployees.data.employees || []).reduce((acc, employee) => {
        acc[employee.id] = employee;
        return acc;
      }, {});

      for (let employeeIndex = 0; employeeIndex < action.payload.length; employeeIndex += 1) {
        const employee = action.payload[employeeIndex];
        employeesByIdMap[employee.id] = {
          ...employeesByIdMap[employee.id],
          ...employee,
        };
      }

      state.storeEmployees.data.employees = Object.values(employeesByIdMap);

      const existingShifts = Object.entries(state.storeEmployees.data.shifts).reduce((acc, [date, shifts]) => {
        acc[date] = shifts.map((shift) => shift.id);
        return acc;
      }, {});
      state.storeEmployees.data.shifts = action.payload.reduce((acc, employee) => {
        employee.shift.forEach((shift) => {
          const shiftDate = TimeHelper.toStandardFormat(TimeHelper.toDayjs(shift.start, action.meta.arg.timezone));
          if (!acc[shiftDate]) {
            acc[shiftDate] = [];
          }
          if (!existingShifts[shiftDate] || !existingShifts[shiftDate].includes(shift.id)) {
            acc[shiftDate].push(shift);
          }
        });
        return acc;
      }, state.storeEmployees.data.shifts);
    });
    builder.addCase(fetchEmployees.rejected, (state, action) => {
      state.storeEmployees.loading = false;
      state.storeEmployees.error = action.error; // todo check it
    });
    builder.addCase(getEmployeesByIds.fulfilled, (state, action) => {
      const employeesByIdMap = state.storeEmployees.data.employees.reduce((acc, employee) => {
        acc[employee.id] = employee;
        return acc;
      }, {});

      for (let employeeIndex = 0; employeeIndex < action.payload.length; employeeIndex += 1) {
        const employee = action.payload[employeeIndex];
        employeesByIdMap[employee.id] = {
          ...employeesByIdMap[employee.id],
          ...employee,
        };
      }

      state.storeEmployees.data.employees = Object.values(employeesByIdMap);
    });
    builder.addCase(getStoreEmployees.pending, (state) => {
      state.storeEmployees.loading = true;
      state.storeEmployees.error = null;
    });
    builder.addCase(getStoreEmployees.fulfilled, (state, action) => {
      state.storeEmployees.loading = false;
      state.storeEmployees.error = null;

      const employeesByIdMap = state.storeEmployees.data.employees.reduce((acc, employee) => {
        acc[employee.id] = employee;
        return acc;
      }, {});

      for (let employeeIndex = 0; employeeIndex < action.payload.length; employeeIndex += 1) {
        const employee = action.payload[employeeIndex];
        employeesByIdMap[employee.id] = {
          ...employeesByIdMap[employee.id],
          ...employee,
        };
      }

      state.storeEmployees.data.employees = Object.values(employeesByIdMap);
    });
    builder.addCase(getStoreEmployees.rejected, (state, action) => {
      state.storeEmployees.loading = false;
      state.storeEmployees.error = action.error;
    });
  },
});

export const { updateShifts } = slice.actions;
export default slice.reducer;
