import { Employee } from '../../../types';
import { parseEmployeeNameWithEmoji } from '../../../utils';

const employeeToOption = (employee: Employee, inactive?: boolean) => {
  const { id, firstName, lastName } = employee;
  const nameValues = parseEmployeeNameWithEmoji(`${firstName} ${lastName}`);
  return {
    id,
    iconLabel: nameValues.name,
    label: nameValues.fullName,
    inactive: inactive || false,
  };
};

export const createEmployeeOptions = (params: {
  employeeId: string | null;
  storeEmployees: Employee[] | undefined;
  availableEmployees: Employee[];
}) => {
  const availableEmployeesByIdMap = params.availableEmployees.reduce((acc, employee) => {
    acc[employee.id] = employee;
    return acc;
  }, {});

  const options = (params.storeEmployees || []).map((employee) => {
    const inactive = !availableEmployeesByIdMap[employee.id];

    return employeeToOption(employee, inactive);
  });

  return options.sort((optionA, optionB) => {
    if (optionA.inactive && !optionB.inactive) return 1;
    if (!optionA.inactive && optionB.inactive) return -1;

    return optionA.label.localeCompare(optionB.label);
  });
};
